import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';

const API_URL = "http://108.165.179.193:8000";

const PrevisaoProducaoStrogonoffCarne = () => {
  // Estado para a data selecionada
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  // Estado para a previsão retornada pela API
  const [previsao, setPrevisao] = useState(null);
  // Estado para o loading
  const [loading, setLoading] = useState(false);
  // Pega o token do contexto
  const { auth } = useContext(AuthContext);

  const buscarPrevisao = async () => {
    try {
      setLoading(true);
      // Formata a data no padrão YYYY-MM-dd
      const dataFormatada = format(dataSelecionada, "yyyy-MM-dd");

      // Faz a requisição GET para o endpoint de previsão do strogonoff de carne
      const response = await axios.get(`${API_URL}/producao/prever/strogonoff-carne`, {
        params: { data: dataFormatada },
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      });
      setPrevisao(response.data);
    } catch (err) {
      console.error('Erro ao buscar previsão:', err);
      setPrevisao(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        📅 Previsão de Produção - Strogonoff de Carne
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="Selecionar Data"
                value={dataSelecionada}
                onChange={(newValue) => setDataSelecionada(newValue)}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                onClick={buscarPrevisao}
                disabled={loading}
                sx={{ height: '100%' }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>

      {previsao ? (
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="subtitle1" fontWeight={500}>
            {Object.keys(previsao)[0]}
          </Typography>
          <Typography variant="h6">
            {Object.values(previsao)[0]} unidades
          </Typography>
        </Paper>
      ) : (
        !loading && (
          <Typography variant="body2" mt={2}>
            Selecione uma data e clique em "Buscar" para ver a previsão de produção.
          </Typography>
        )
      )}
    </Box>
  );
};

export default PrevisaoProducaoStrogonoffCarne;
